<template>
    <div class="relative i_w">
        <div class="icon">
            <i class="fi" :class="item.icon" />
        </div>
        <a-badge 
            v-if="item.badge" 
            :count="getCounter"
            :number-style="{
                boxShadow: '0 0 0 0',
                backgroundColor: primaryColor
            }"/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            primaryColor: state => state.config.primaryColor
        }),
        getCounter() {
            return this.$store.getters['navigation/getMenuCounter'](this.item.name)
        }
    }
}
</script>

<style lang="scss" scoped>
.icon{
    font-size: 19px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    .fi{
        width: 18px;
        height: 22px;
    }
}
.i_w{
    &::v-deep{
        .ant-badge{
            position: absolute;
            z-index: 2;
            top: -8px;
            right: -9px;
            .ant-badge-count{
                line-height: 16px;
                min-width: 16px;
                height: 16px;
                font-size: 10px;
                padding: 0 5px;
                &.ant-badge-multiple-words{
                    padding: 0 5px;
                }
            }
        }
    }
}
</style>